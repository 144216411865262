@import "variable";

.smart-table-wrapper {
    width: 100%;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: black;

    .ReactTable {
        overflow: auto;
        margin: 50px 0;
        color: white;
        border: 0;
        flex-wrap: wrap-reverse;
        .rt-table {
            .rt-tbody {
                background-color: white;
                overflow: visible;
                border: 0;
                .rt-tr-group {
                    border: 0;
                    margin: 0;
                    .rt-tr {
                        .rt-td {
                            min-width: 100px;
                            border: none;
                        }
                    }
                }
            }
        }
        .-pagination {
            .-btn {
                background-color: white;
            }
        }
        .rt-resizer {
            width: 2px;
            right: 0px;
            background-color: #c8c8c8;
            margin: 7px 0;
            z-index: 0;
        }

    }

    // asc/desc table icon override
    .ReactTable {
        .rt-thead {
            .rt-resizable-header {
                box-shadow: none;
                border: none;
                //background: linear-gradient(90deg, rgba(120, 133, 206, 1) 60%, rgba(131, 143, 214, 1) 100%);
                height: 45px;
                width:auto;
                min-width: 100px;
                &:after {
                    content: "";
                    width: 30px;
                    height: 30px;
                    //background: url('./../shared/img/smart-table/sort.png') 0 0 no-repeat;
                    //float: right;
                    position: relative;
                    top: 4px;
                    left: 4px;
                    opacity: 0.5;
                }

                &:last-of-type {
                    .rt-resizer {
                        display: none;
                    }
                }
            }
        }

        .-sort-desc {
            &:after {
                background: url('../assets/sort-up.png') 0 0 no-repeat;
                left: 28px;
            }
        }

        .-sort-asc {
            &:after {
                background: url('../assets/sort-down.png') 0 0 no-repeat;
                left: 28px;
            }
        }

        // checkbox
        .filter-checkbox {
            position: relative;
            top: 10px;
            left: 45%;
            height: 30px;
            width: 30px;
        }

        // fix when date range is over filter dropdown
        .css-26l3qy-menu {
            z-index: 3;
        }

        // table design that overrides default table css
        .rt-thead.-header {
            border-bottom: 2px solid #e6e6e6;
            box-shadow: none;
            //background-color: #7e8ad3;
            color: #696969;

            font-size: 12px;
            font-weight: bold;
            height: 45px;

            .header-cell {
                height: 35px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                span {
                    font-weight: bold;
                    color: $color-main-black;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    //z-index: 999;
                }

                // icon on the filter in the header
                .filter-img {
                    height: 21px;
                    width: 21px;
                }
            }

        }
        .rt-resizable-header {
            display: flex;
            align-items: center;
            width:auto;
            .rt-resizable-header-content {
                width: 100%;
            }
            &:after {
                height: 20px;
                left: initial;
                bottom: initial;
            }
        }

        .rt-tr.-odd {
            background-color: $color-main-white;
        }

        .rt-tr.-even {
            background-color: $color-main-white;
        }

        // all rows
        .rt-tr-group {
            height: auto !important;
            color: black;
            margin: 8px 0;

            .rt-tr {
                border-bottom: 1px solid $color-main-light-gray;
                font-size: 14px;
                .rt-td {
                    align-items: center;
                    display: flex;
                    white-space: normal;
                }
            }
        }

        .rt-thead.-filters {
            border: none;
        }

        // position filters verttical middle
        .date-range-wrapper,
        .singleselect,
        .filter-checkbox,
        .rt-th > input {
            transform: translate(0px, 40px);
            margin-bottom: 50px;
        }

        .number-filter {
            transform: translate(0px, 13px);
            margin-bottom: 23px; // pushes the filter row down and give space for filters
        }

        .rt-th > input,
        .singleselect {
            width: 90%
        }

        .-pagination {
            justify-content: space-between;
            border: none;
            box-shadow: none;
            // next and prevoius buttons
            .-previous, .-next {
                flex: 0;
                > button {
                    width: 45px ;
                    color: $color-main-blue;
                    font-size: 20px;
                    border: none;
                    &:hover {
                        opacity: 0.6;
                    }
                    &:disabled {
                        color: $color-main-light-gray;
                    }
                }
            }

            // remove margin and padding in order to get spave for the design
            .-pageInfo {
                display: flex;
                align-items: center;
                .-pageJump {
                    > input {
                        margin-top:2px;
                        border: none;
                        padding: 5px 1px;
                        font-weight: lighter;
                    }
                }
            }

            // remove flex to get elements close to each other
            .-center {
                flex: 0;
            }
        }

        .-pagination {
            color: black
        }

        .-totalPages {
            width: 4vw;
            padding: 1vw;
        }

        .-pageSizeOptions {
            display: none;
        }
    }
    .button-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        > button {
            background-color: transparent;
        }

        // action icons
        .action-icon-pencil {
            margin-bottom: 5px;
        }
        .action-icon-eye {
            width: 20px;
            border: none;
            margin: 0px 10px;
        }
        .action-icon-pencil {
            height: 20px;
            margin-bottom: 5px;
            background: url('../assets/pencil.png');
            background-size: 100% 100%;
        }
        .action-icon-eye {
            height: 12px;
            background: url('../assets/view-icon.png');
            background-size: 100% 100%;
        }
        .action-icon-delete {
            height: 30px;
            width: 30px;
            margin-left: 6px;
            border-color: transparent;
            background: url('../assets/delete.png');
            background-size: 100% 100%;
        }

        .custom-action {
            border: none;
            background: transparent;
            //height: 30px;
            //width: 30px;
            //margin: 0px 5px;
            //display: flex;
            //justify-content: center;
            //align-items: center;
        }

        .action-icon {
            border: none;
            color: #7986CF;
            opacity: 0.5;
            cursor: pointer;
        }
    }
}
