.google-maps-container {
    width: 100%;
    height: 400px;
}

.google-maps-wrapper {
    height: 100%;
}

.search-boxes-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    .search-box {
        //height: 50px;
        position: relative;

        .subjects-add-wrapper {
            display: flex;
            align-items: center;
            width: 100%;
            button {
                padding: 0 14px;
                margin-left: 10px;
                height: 31px;
                box-shadow: inset 0 1px 3px 0 rgba(208, 208, 208, 0.5);
                border: solid 1px #326c91;
                border-radius: 0;
                position: relative;
                top: 2px;
                span.add-location-button {
                    color: #326c91;
                    font-size: 16px;
                    text-transform: none;
                    position: relative;
                    top: 1px;
                }
            }
            @media only screen and (max-width: 736px) {
                margin-top: 0;
                .subjects-multiselect {
                    margin-top: 0;
                }
                button {
                    top: 0
                }
            }
        }
    }
}

.search-boxes-container,
.google-maps-wrapper {
    width: 100%;

    /* Portrait and Landscape */
    @media only screen and (max-width: 736px) {
        width: 100%;
    }
}

.search-box-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 17px 0px 0px 0px;

    .search-input-radius,
    .search-input-area {
        width: 80px;
        padding: 0 8px;
        height: 31px;
        border: 1px solid lightgray;
    }

    .search-input-area {
        width: 100%;
    }

    .availavility-select {
        flex: 2;
    }
    .search-input-area {
        flex: 5;
    }
    .radius-select {
        flex: 1;
        min-width: 124px;
        > div {
            div {
                padding-right: 0;
            }
        }
    }

    @media only screen and (max-width: 736px) {
        //flex-direction: column;
        flex-wrap: wrap;
        .search-input-area {
            flex: 5 1 100%;
            order: 1;
            height: 29px;
            line-height: 29px;
        }

        .availavility-select, .search-input-area, .radius-select {
            width: 100%;
            margin-bottom: 10px;
        }
    }
}

.google-maps-bottom-text-container {
    left: calc(70% - 148px);
}

.wrapper.rtl .google-maps-bottom-text-container,
.google-maps-bottom-text-container {
    position: relative;
    bottom: 31px;
    right: calc(100% - 148px);
    font-size: 17px;
    color: #979797;
    width: 148px;
    height: 31px;
    border-radius: 1px;
    border: solid 1px #e9e9e9;
    background-color: #ffffff;

    display: flex;
    flex-direction: row;
}

.add-searchbox {
    position: relative;
    right: 25px;
    bottom: 27px;
    cursor: pointer;
}

.remove-searchbox {
    position: absolute;
    left: -23px;
    top: 24px;
    cursor: pointer;
}

/// fix z-index for modal
.pac-container {
    z-index: 999999;
}

.areas-by-radius-submit-button {
    border: none;
    width: 211px;
    height: 56px;
    border-radius: 30px;
    background-color: #00b3de;
    font-size: 19px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #f2f2f2;
    margin: 20px 360px;
    cursor: pointer;

    /* Portrait and Landscape */
    @media only screen and (max-width: 736px) {
        width: 120px;
        height: 34.2px;
        border-radius: 30px;
        background-color: #00b3de;
    }
}

.areas-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    > h3 {
        margin-top: 20px;
        font-size: 20px;
    }
    .areas-add {
        display: flex;
        flex: 4;
        flex-direction: column;
        width: 100%;
        > h3 {
            margin-top: 20px;
            font-size: 20px;
        }
        .search-boxes-container {
            width: 100%;
        }
        .google-maps-container {
            width: 100%;
        }
        &.areas-container-modal {
            .search-boxes-container {
                width: 100%;
            }
            .google-maps-container {
                width: 100%;
            }
        }
    }
    .areas-summary-container {
        display: flex;
        flex: 2;
        justify-content: flex-end;
        .areas-summary {
            background-color: white;
            position: relative;
            top: 16px;
            width: 331px;
            height: fit-content;
            border-radius: 3px;
            border: solid 0.5px #326c91;
            margin-left: 50px;

            .areas-summary-top {
                //top: 21px;
                position: relative;
                left: 3px;
                text-align: center;
                padding-top: 10px;
                z-index: 99999999;
                font-size: 17px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                //letter-spacing: 0.86px;
                color: #18b2de;
            }

            .as-title {
                margin: 30px 33px 0 33px;
                text-align: center;
                padding-bottom: 16px;
                border-bottom: 2px solid #326c91;
                img {
                    width: 50px;
                    height: 50px;
                }
                h3 {
                    width: 214px;
                    font-size: 18px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 0.96px;
                    text-align: center;
                    color: #326c91;
                    margin: 10px auto 0 auto;
                }
            }

            .areas-include, .areas-exclude {
                margin: 0 30px;
                > h4.title {
                    text-decoration: underline;
                    color: #326c91;
                    margin-top: 28px;
                    font-size: 14px;
                }
                .as-address {
                    font-weight: bold;
                }
                .as-subjects {

                }
            }

            .areas-include {

            }

            .areas-exclude {

            }
        }
    }
    button.go-to-button {
        color: #326c91;
        border: 1px solid #326c91;
        padding: 0 20px;
        height: 37px;
        border-radius: 17px;
        background-color: transparent;
        display: flex;
        align-items: center;
        font-size: 16px;
        position: relative;
        top: 1px;
        margin-right: auto;
        align-self: flex-end;
        margin-left: 20px;

        > svg {
            fill: #326c91;
            width: 19px;
            position: relative;
            margin: 0 10px 0 8px;
        }
    }

    @media only screen and (max-width: 1320px) {
        flex-direction: column;
        padding-left: 30px;
        padding-right: 30px;
        .areas-summary-container {
            position: absolute;
            top: -17px;
            left: 0;
            right: 0;
            z-index: 9999;
            margin: 0 auto;
            justify-content: center;
            .areas-summary {
                margin: 0;
                height: unset;
                padding-bottom: 30px;

            }
            .as-title {
                display: none;
            }
        }
    }

}

.subjects-multiselect {
    width: 100%;
    //height: 31px;
    margin-top: 5px;
    display: flex;
    flex: 1;
    .subjects-multiselect__control {
        width: 100%;
        //height: 31px;
        min-height: 31px;
        display: flex;
        flex-direction: row;
        align-content: space-between;
        border-radius: 1px;
        box-shadow: none;
        font-weight: 500;
        padding: 0 5px;
        border-color: lightgray;
        color: #326c91;
        font-size: 16px;
        > span {
            margin-right: 4px;
        }

        &.subjects-multiselect__control--is-focused {
            border-color: #c8c8c8;
        }

        .subjects-multiselect__value-container {
            padding: 0;
            //height: 31px;
        }
        .subjects-multiselect__value-container--has-value {
            //height: 31px;
            min-height: 31px;
            .subjects-multiselect__multi-value {
                background-color: unset;
                height: 25px;

                .subjects-multiselect__multi-value__label {
                    color: #326c91;
                    font-size: 16px;
                    padding: 0;
                    padding-left: 0;
                }
                .subjects-multiselect__multi-value__remove {
                    display: none;
                }
            }
        }
    }
    .subjects-multiselect__clear-indicator, .subjects-multiselect__indicator-separator {
        display: none;
    }
    .subjects-multiselect__dropdown-indicator {
        height: 31px;
        padding: 4px;
    }
    .subjects-multiselect__menu {
        margin-top: 4px;
        .subjects-multiselect__menu-list {
            padding: 0;
            background-color: #ececec;
            @media only screen and (max-width: 736px) {
                width: 334px
            }
            .subjects-multiselect__option {
                width: 100%;
                height: 33px;

                display: flex;
                flex-direction: row;
                //justify-content: space-between;
                align-content: center;
                align-items: center;
                &:hover {
                    background-color: #6d7278 !important;
                    color: white;
                    cursor: pointer;
                }

                &.subjects-multiselect__option--is-selected {
                    background-color: unset;
                    color: unset;
                    &:hover {
                        color: white;
                    }
                }
            }
        }
    }
}

.layout-container-wrapper-categories {
    .layout-labels, .categories-save-button{
        margin-left: 75px;
    }
}
