.providerPhones{
  width:50% !important;

  .pd-field-name {
    width: 40%;
    font-size: 16px;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0;
    margin-top: 8px;
    color: #777777;
  }
}