@import "variable";


.smart-form {
  padding: 20px 45px 35px 45px;
  .card__title {
    h4 {
      text-align: center;
      color: #326c91;
      //margin-top: 26px;
    }
  }
  form {
    .form-input-fields {
      position: relative;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .form__form-group {
        //width: 284px;
        width: 48%;
        //flex: 1;
        .form__form-group-field {
          //background-color: #ffffff;
          input, textarea, .multi-dates-wrapper, .working-hours {
            background-color: #ffffff;
          }
          input {
            //max-width: 310px;
            width: 100%;
            height: 31px;
            //object-fit: contain;
            border-radius: 4px;
            //background-color: #ecf8ff;
            //color: #a9a9a9;
            border: solid 1px #d3d7de;
            &:hover {
              border-color: #7986CF
            }
          }
          .form__form-group {
            width: auto;
            flex: 1;
          }
          .react-dropdown-tree-select {
            width: 100%;
            .dropdown {
              width: 100%;
              .dropdown-trigger {
                display: flex;
                border-radius: 4px;
                border: solid 1px #d3d7de;
                outline: none;
                .tag-list {
                  flex: 1
                }
              }
            }
            .dropdown-content {
              width: 100%;
              max-height: 520px;
              overflow: auto;
              .search {
                display: none;
              }
            }
            .node {
              .toggle {
                &:focus {
                  outline: none;
                }
              }
              .checkbox-item {
                width: fit-content;
                margin: 0 4px;
              }
              &.leaf {
                padding: 0 20px;
              }
            }
          }
        }
      }
      .input-field-wide{
        width:100% !important;
      }
    }

    .sf-buttons {
      padding-top: 30px;
      button {
        color: white;
        font-size: 16px;
        border: 0;
        width: 92px;
        height: 31px;
        border-radius: 17px;
        background-color: #326c91;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease-in-out;
        &:hover {
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
          transform: scale(1.05);
        }
        &[type="submit"] {
          background-color: #316C91;
          color: white;
        }
        &[type="button"] {
          background-color: #E6E6E6;
          color: #326c91;
        }
      }
    }
  }
  .form__form-group {
    //.react-select__control {
    //  width: 301px;
    //  height: 31px;
    //  min-height: 31px;
    //  //object-fit: contain;
    //  border-radius: 4px;
    //  border: solid 1px #d3d7de;
    //  background-color: #ecf8ff;
    //  .react-select__value-container, .react-select__indicators {
    //    //height: 31px;
    //  }
    //}
    .react-select__control {
      //max-width: 310px;
      width: 100%;
      height: 31px;
      object-fit: contain;
      border-radius: 4px !important;
      background-color: #ffffff;
      color: #a4a4a4;
      cursor: pointer;
      //border: solid 1px #eaeef5;
    }
    &.multiselect {
      .react-select__control {

        height: unset;
      }
    }
  }
}

.action-icon-plus {
  height: 30px;
  width: 30px;
  border-color: transparent;
  background: url('../assets/plus.png');
  background-size: 100% 100%;
}
