/// Modal

.messages-modal {
    width: 40%;
    height: 100%;
    border-radius: 26.5px;
}

.messages-modal-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 40px;
}


.messages-modal-title {
    align-self: center;

    font-size: 22px;
    font-weight: 600;
    color: #326c91;
}

.messages-type-container,
.message-template-container,
.message-subject-container,
.message-content-container {

    font-size: 16px;
    font-weight: normal;
    color: #326c91;

    margin-bottom: 20px;
}

.messages-type-container,
.message-template-container {
    width: 30%;
}

.messages-type-label,
.message-template-label,
.message-subject-label,
.message-content-label {
    margin-bottom: 5px;
}

.message-subject-container,
.message-content-container,
.message-subject-field,
.message-content-field {
    width: 100%;
}

.message-subject-field,
.message-content-field {
    border: none;
    background-color: #ececec;
    padding: 10px;
}

.message-subject-field {
    height: 25px;
}

.message-content-field {
    height: 110px;
}

.messages-submit {
    width: 154px;
    height: 42px;
    border-radius: 26.5px;
    background-color: #02b2de;
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    border: none;
    align-self: center;
}

/// END -----Modal

.messages-open-button {
    width: 254px;
    height: 42px;
    border-radius: 26.5px;
    background-color: #02b2de;
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    border: none;
    margin-top: 60px;
}