main {
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Helvetica, "Helvetica Neue", "HelveticaNeue-Light", "Helvetica Neue Light", Arial, "Lucida Grande", sans-serif;
  font-size: 13px;
  line-height: 1.6;
  text-align: start;

  @include themify($themes) {
    color: themed('colorText');
  }
}

* {
  box-sizing: inherit;
}

ul,
ol {
  padding-left: 15px;
  margin-bottom: 0;
}

a {
  color: $color-blue;
  transition: all 0.3s;

  &:hover {
    text-decoration: none;
    color: $color-blue-hover;
  }
}

img {
  width: 100%;
}

textarea:focus,
input:focus {
  outline: none;
}

.modal-dialog {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.details-view{
  padding-top:14px;
}