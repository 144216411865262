@import "../settings/variable";

.user-container {
  .user-tab-menu {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid $color-main-gray;

    > a {
      font-weight: 600;
      text-align: center;
      color: $color-main-blue;
      margin: 0 0 -1px;
      padding: 15px 25px;
      border: 1px solid transparent;
      border-top: 2px solid transparent;

      &:hover {
        color: $color-main-light-gray;
        cursor: pointer;
      }

      &.tab-active {
        color: $color-main-blue;
        border: 1px solid $color-main-gray;
        border-top: 2px solid $color-main-blue;
        border-bottom: 1px solid #fff;
      }
    }
  }

  .tab-content {
  }

  .user-details-container {
    flex-direction: column;
    box-sizing: border-box;
    color: black;
    display: flex;
    align-items: center;
    padding: 0;
    .user-details {
      margin-top: 40px;
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: $pale-gray;
      padding: 30px;

      .pd-head {
        width: 100%;
        display: flex;
        justify-content: space-between;

        > span {
          font-size: 20px;
          font-weight: bold;
          color: #346d92;
        }

        > button {
          color: #326c91;
          border: 1px solid #326c91;
          width: 99px;
          height: 37px;
          border-radius: 17px;
          background-color: transparent;
          display: flex;
          align-items: center;

          > svg {
            fill: #326c91;
            width: 19px;
            position: relative;
            margin: 0 10px 0 8px;
          }

          span {
            font-size: 16px;
            position: relative;
            top: 1px;
          }
        }
      }

      .pd-body {
        display: flex;
        flex-wrap: wrap;
        margin-top: 12px;

        .pd-field {
          color: #777777;
          display: flex;
          width: 50%;
          margin-top: 8px;

          .pd-field-name {
            width: 40%;
            font-size: 16px;
            font-weight: bold;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .pd-field-value {
            width: 60%;
            font-size: 15px;
            font-weight: normal;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }

      .pd-footer {
        padding: 30px 0 30px 0;
        border-bottom: 1px solid #979797;
        margin-bottom: 5px;
        display: flex;

        .pd-footer-item {
          margin-right: 65px;
          display: flex;
          align-items: baseline;

          > h3 {
            font-size: 26px;
            font-weight: normal;
            color: #346d92;
          }

          > span {
            margin-left: 8px;
            font-size: 15px;
            color: #777777;
          }
        }
      }
    }

    .user-details-telephones {
      width: 100%;
      background-color: #f5f9fc;
      margin-top: 30px;
      padding: 30px;

      .udt-head {
        display: flex;
        justify-content: space-between;
        h3 {
          font-size: 20px;
          font-weight: bold;
          color: #346d92;
        }

        > button {
          color: #326c91;
          border: 1px solid #326c91;
          width: 99px;
          height: 37px;
          border-radius: 17px;
          background-color: transparent;
          display: flex;
          align-items: center;

          > svg {
            fill: #326c91;
            width: 19px;
            position: relative;
            margin: 0 10px 0 8px;
          }

          span {
            font-size: 16px;
            position: relative;
            top: 1px;
          }
        }
      }

      .telephones-wrapper {
        margin-top: 10px;

        .telephone {
          margin-bottom: 5px;

          > span {
            font-size: 16px;
            font-weight: bold;
            color: #777777;

            > span {
              color: #888888;
            }
          }
        }
      }

    }
  }
}

.telephones-modal {
  padding: 20px 45px 35px 45px;
  > h3 {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #346d92;
  }

  .add-telephone {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    button {
      color: white;
      font-size: 16px;
      border: 0;
      height: 31px;
      padding: 0 20px;
      border-radius: 17px;
      background-color: #326c91;
      display: flex;
      align-items: center;
      justify-content: center;
      //background-color: #E6E6E6;
      //color: #326c91;

      span {
        > svg {
          position: relative;
          left: -6px;
        }
        > span {
          position: relative;
          top: 1px
        }
      }
    }
  }

  .telephone-inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    .ti-row {
      max-width: 410px;
      width: 100%;
    }

    .telephone-input-wrapper {
      display: flex;
      align-items: center;
      width: 120%;

      > svg {
        width: 30px;
        margin-left: 4px;
        position: relative;
        bottom: 6px;
        cursor: pointer;
      }
      .delete-icon{
        height: 50px;
        width: 50px;
        margin-top:15px;
        margin-left: 5px;
      }

      .btn{
        margin:0;
        padding:5px;
        width:300px !important;
        margin-bottom:10px;
        margin-left: 10px;
        font-size: 12px;

        .btn-primary {
          margin:0;
      }
    }
      .form__form-group-input-wrap{
        //width: 360px;
        margin-bottom: 16px;
        margin-right:10px;
        background-color: #ecf8ff;
        border-radius: 4px !important;
        font-color: gray;
        color:gray;

        .react-select__control {
        }
        .css-yk16xz-control{
          background-color: #ecf8ff;
          border-radius: 4px !important;
          font-color: gray;
          color:gray;
        }
      }
    }
    .telephone-input {
      padding: 0 10px;
      margin-bottom: 10px;
      max-width: 310px;
      width: 100%;
      height: 38px;
      object-fit: contain;
      border-radius: 4px;
      background-color: #ecf8ff;
      color: #a9a9a9;
      border: solid 1px #d3d7de;
    }
  }

  .no-telephones {
    font-size: 17px;
    color: #777;
  }

  .tm-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
    button {
      color: white;
      font-size: 16px;
      border: 0;
      width: 92px;
      height: 31px;
      border-radius: 17px;
      background-color: #326c91;
      display: flex;
      align-items: center;
      justify-content: center;

      &[type="submit"] {
        background-color: #316C91;
        color: white;
      }

      &[type="button"] {
        background-color: #E6E6E6;
        color: #326c91;
      }
    }
  }

}
