

.messages-modal-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;

}


.payment-modal-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 20px;
}

.text-container {
  padding-top: 20px;
  padding-bottom: 40px;
  font-size: large;

}

.buttons-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 47%;
}

.messages-confirm  {
  width: 154px;
  height: 42px;
  border-radius: 26.5px;
  background-color: #02b2de;
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
  border: none;
  align-self: center;
}

.messages-cancel  {
  width: 154px;
  height: 42px;
  border-radius: 26.5px;
  background-color: red;
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
  border: none;
  align-self: center;
}

table-container {
  .row {
    border-bottom-color: gray;
    border-bottom-width: thin;
    border-bottom-style: solid;
    width: 100%;
  }
}

.payment-methods-container {
  border-bottom-color: lightgray;
  border-bottom-width: thin;
  //border-bottom-style: solid;
  width: 100%;

  .add-container{
    padding-left:0;
  }
  .card-body{
    padding: 10px 30px
  }
  .smart-table-wrapper {
    .ReactTable {
      margin: 0px 0px
    }
  }
}