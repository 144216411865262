.call-details-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  padding: 10px 16px 16px 16px;
  margin: 0 20px 20px 20px;
  background-color: #e0f4ff;
  justify-content: center;
  box-shadow: 0 5px 14px -7px rgba(0,0,0,0.61);
  > button {
    position: absolute;
    right: 10px;
    top: 10px
  }
  .call-details-title {
    display: flex;
    justify-content: center;
    .call-details-icon {
      margin-right: 20px;
    }
    > span {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
    }
  }
  .call-details {
    margin-top: 15px;
    display: flex;
    align-items: center;
    position: relative;
    .call-details-labels {
      display: flex;
      flex-direction: column;
      align-self: flex-start;

      .call-details-label {
        font-size: 14px;
        font-weight: bold;
        min-height: 22px;

      }
    }
    .call-details-values {
      margin-left: 30px;
      display: flex;
      flex-direction: column;
      align-self: flex-start;

      .call-details-value {
        font-size: 14px;
        min-height: 22px;
      }

    }
    .call-details-notes {
      display: flex;
      flex: 1;
      margin-left: 100px;
      flex-direction: column;
      align-items: flex-start;
      .notes-label {
        font-size: 14px;
        font-weight: bold;
      }
      > textarea {
        height: 90px;
        width: 100%;
        border-color: #ccc;
      }
      > button {
        height: 20px;
        border-radius: 15px;
        background-color: #02b2de;
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
        border: none;
        padding: 0 12px;
        margin-left: auto;
        margin-top: 5px;      }

    }
  }
  .save-message {
    font-size: 14px;
    font-weight: bold;
    margin: 0 auto;
  }
}
