@import 'variable';

.dropzone {
  width: 100%;
  display: flex;
  position: relative;
  cursor: pointer;

  &.dropzone--single {
    height: 100px;

    .dropzone__img {
      margin: 0;
      height: 100%;
      width: 100%;
      text-align: center;

      img {
        padding: 20px;
        height: 100%;
        width: auto;
      }
    }

    .dropzone__input:hover {
      background-size: 30px 30px;
      animation: striped 2s linear infinite;

      @keyframes striped {
        from {
          background-position: 0 0
        }

        to {
          background-position: 60px 30px
        }
      }
    }
  }

  &.dropzone--custom-height {
    min-height: 100px;
    height: auto;

    .dropzone__img {
      max-width: 100%;

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }

  &.dropzone--multiple {
    min-height: 400px;

    .dropzone__input {
      min-height: 400px;
    }
  }
}

.dropzone__input {
  width: 100%;
  height: 100%;
  min-height: 298px;
  display: flex;
  position: absolute !important;
  cursor: pointer;
}

.dropzone__drop-here {
  margin: auto;
  color: $color-additional;
}

.dropzone__imgs-wrapper {
  padding: 30px 20px;
  width: calc(100% + 30px);
  display: flex;
  flex-wrap: wrap;
}

.dropzone__img {
  margin-bottom: 30px;
  width: calc(16.6667% - 30px);
  height: 150px;
  overflow: hidden;
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    transition: all 0.3s;
    cursor: default;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }

  &:hover {

    &:before {
      background-color: rgba(25, 25, 25, 0.6);
    }

    .dropzone__img-delete {
      opacity: 1;
    }

    .dropzone__img-name {
      opacity: 0.7;
    }
  }
}

.dropzone__img-name {
  color: white;
  position: absolute;
  font-size: 12px;
  text-align: center;
  opacity: 0;
  transition: all 0.3s;
  z-index: 10;
  width: 100%;
  line-height: 12px;
  margin: 0;
  top: calc(50% - 6px);
}

.dropzone__img-delete {
  transition: all 0.3s;
  position: absolute;
  top: 10px;
  right: 15px;
  z-index: 10;
  cursor: pointer;
  background: transparent;
  opacity: 0;
  color: white;
  font-size: 9px;
  border: 1px solid white;
  text-transform: uppercase;
  padding: 2px 7px;
  line-height: 10px;
}