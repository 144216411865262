.unavailability-form-container {

    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    padding: 0 0px;
    margin-top: 50px;
}

.datime-picker {
    display: flex;
    flex-direction: row;

    align-items: center;
}

.unavailability-datepickers {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.datetime-picker-date,
.datetime-picker-time {
    font-size: 17px;
    margin: 0 5px;
    white-space: nowrap;
}

.datepicker-2,
.datepicker-2 {
    margin: 0 40px;
}

.date-switch {
    input {
        width: 190px;
        height: 30px;
    }
}

.time-switch {
    input {
        height: 30px;
    }
}