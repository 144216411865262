.container {
  color: $color-main-blue;

  @media screen and (min-width: 768px) {
    width: 100%;
    max-width: 1630px;
  }
}

.wrapper {

  .container__wrap {
    padding-left: 0;
    padding-top: 114px;
    min-height: 100vh;
    transition: padding-left 0.3s;

    @include themify($themes) {
      background: themed("colorBackgroundBody");
    }

    @media screen and (min-width: 576px) {
      padding-left: 250px;
    }
  }

  &.rtl {
    direction: rtl;

    .container__wrap {
      @media screen and (min-width: 576px) {
        padding-left: 0;
        padding-right: 250px;
      }
    }

    .layout--collapse {
      &+.container__wrap {
        @media screen and (min-width: 576px) {
          padding-left: 0;
          padding-right: 60px;
        }
      }
    }
  }
}

.layout {
  &.layout--collapse {
    &+.container__wrap {
      padding-left: 0;

      @media screen and (min-width: 576px) {
        padding-left: 60px;
      }
    }
  }

  &.layout--top-navigation {
    &+.container__wrap {
      @media screen and (min-width: 576px) {
        padding-left: 0;
      }
    }
  }
}