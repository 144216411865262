.reports {
  padding: 0rem 1rem;

  .page-desc{
    padding: 0rem 1rem 2rem 1rem;
  }
  .filters {
    display: flex;
    padding: 1rem;
    .title {
      flex: 2;
    }

    .react-datepicker-wrapper {
      display: flex;
      flex: 8;
    }
  }

  .table{
    width:80%
  }

  tr {
    &:first-child {
      th {
        border-top: 0;
      }
    }
  }


}
