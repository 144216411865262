.layout-form-container-hours {
    padding-left: 67px;
    .layout-form-1 {
        margin: 0;
        &.layout-form-1-ltr {
            padding-right: 70px;
            border-right: 1px solid #c8c8c8;

            @media only screen and (max-width: 736px) {
                padding: 0;
                border: 0;
            }
        }
    }

    .layout-form-2 {
        margin: 0;
        &.layout-form-2-ltr {
            padding-left: 70px;
            padding-right: 0;
            border: 0;
            @media only screen and (max-width: 736px) {
                padding: 0;
                border: 0;
            }
        }
    }

}

.layout-submit-button.work-hours-submit {
    //margin:0 40%;
    margin-top: 30px;
    align-self: center;
    @media only screen and (max-width: 736px) {
        width: unset;
        padding-left: 24px;
        padding-right: 24px;
    }

}


.hours-switch,
.hours-time {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #c8c8c8 !important;
    padding-top: 20px;
    padding-bottom: 6px;

    /* Portrait and Landscape */
    @media only screen and (max-width: 736px) {
        // width: 90% !important;
    }
}

.hours-switch-text {
    opacity: 0.7;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;

}

.hours-switch-from-to-text-1,
.hours-switch-from-to-text-2 {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;

    margin: 0 15px;

    /* Portrait and Landscape */
    @media only screen and (max-width: 736px) {
        font-size: 15px;
        margin: 0px 5px;
    }
}

.time-container {
    display: inline-flex;
    position: relative;
}

.time-icon {
    position: absolute;
    color: #9C9C9D;
    top: 5px;
    left: 5px;
    z-index: 99;
    align-items: center;
    align-content: center;
}

.hours-add-circle-outline {
    position: absolute;
    bottom: 16px;
    left: -43px;
    cursor: pointer;
    font-size: 26px;

    /* Portrait and Landscape */
    @media only screen and (max-width: 736px) {
        bottom: 11px;
        left: -35px;
    }
}

.hours-right-icon-checked,
.hours-right-icon-unchecked {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    border-radius: 100px;
    background-color: #00b3de;
    //padding-top: 8px;
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: white;

    margin-left: 15px;
    cursor: pointer;

    /* Portrait and Landscape */
    @media only screen and (max-width: 736px) {
        font-size: 13px;
        font-weight: 500;
        width: 43px;
        height: 43px;
        border-radius: 100px;
        margin-left: 3px;
        //padding-top: 7px;
    }
}

// override material ui scss
.delete-timeslot,
.hours-right-icon-empty {
    width: 42px !important;
    height: 42px !important;
    padding-top: 8px !important;
    margin-left: 15px !important;

    /* Portrait and Landscape */
    @media only screen and (max-width: 736px) {
        margin-left: 6px !important;
    }
}

.delete-timeslot {
    cursor: pointer;

    /* Portrait and Landscape */
    @media only screen and (max-width: 736px) {
        width: 30px !important;
        height: 35px !important;
    }
}

.hours-right-icon-unchecked {
    background-color: #2c2c2e;
}

.hours-time-container {
    width: 89.3%;
    margin-left: 33px;
    position: relative;
    border-bottom: 1px solid lightgray;
    border-bottom-opacity: 50%;

    .hours-time-row-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: bold;
    }


    .hours-time-dayofweek {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-top: 15px;
        padding-bottom: 15px;

        /* Portrait and Landscape */
        @media only screen and (max-width: 736px) {
            justify-content: space-between;
        }
    }

    &.hours-time-container-ltr {
        margin-left: 0;
        margin-right: 33px;
        .hours-add-circle-outline {
            left: unset ;
            bottom: 24px;
            right: -43px;
            @media only screen and (max-width: 736px) {
                left: unset;
                right: -43px;
            }
        }
    }
}

.hours-label-1 {
    font-size: 21px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    //line-height: 1.21;
    //letter-spacing: 0.37px;
    //text-align: center;
    margin-top: 30px;
    margin-right: 20px;

    /* Portrait and Landscape */
    @media only screen and (max-width: 736px) {
        font-size: 24px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;

    }
}

.label-2 {
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;


    /* Portrait and Landscape */
    @media only screen and (max-width: 736px) {
        margin-bottom: 20px;
        font-size: 15px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;

        opacity: 0.7;
    }
}

.hours-label-2,
.hours-label-3 {
    align-self: flex-start;
    margin: 0px 0px 0px 0px;
}

.hours-label-2,
.hours-label-3 {

    font-size: 15px;
    font-weight: 100;

    /* Portrait and Landscape */
    @media only screen and (max-width: 736px) {
        width: 190px;
    }
}


