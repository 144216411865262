$color-main-blue: #326c91;
$color-main-black: #4a4a4a;
$color-main-gray: #a4a4a4;
$color-main-light-gray: #dddddd;
$color-main-white: #ffffff;

$color-accent: #7986CF;
$color-accent-hover: darken($color-accent, 10%);
$color-additional: #999999;
$color-additional-hover: darken($color-additional, 10%);

$color-yellow: #f6da6e;
$color-yellow-hover: darken($color-yellow, 10%);

$color-violet: #c88ffa;

$color-red: #ff4861;
$color-red-hover: darken($color-red, 10%);

$color-blue: #70bbfd;
$color-blue-hover: darken($color-blue, 10%);

$color-gray: #787985;
$pale-gray: #f5f9fc;

$color-btn-red: #e83837;
$color-btn-red-hover: darken($color-btn-red, 10%);

$header-height: 90px;