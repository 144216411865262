@import "variable";

.date-picker {
  width: 100%;

  & > div, .react-datepicker-wrapper, .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker__time-list {
    padding: 0;
  }

  .react-datepicker__time-list-item {
    width: 50px;
  }

  .react-datepicker__time {
    overflow: hidden;
  }

  &.date-picker--interval {
    display: flex;

    & > div, .react-datepicker-wrapper, .react-datepicker__input-container {
      max-width: 170px;
    }
  }

  .multi-dates-wrapper {
    width: 100%;
    min-height: 31px;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    border-radius: 4px;
    border: solid 1px #d3d7de;
    padding: 5px 10px;
    font-size: 12px;
    transition: border 0.3s;
    background: transparent;
    cursor: pointer;
    &:hover {
      border-color: #7986CF
    }
    .date {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 4px;
      padding: 2px 4px;
      background-color: #f0f0f0;
      border-radius: 2px;
      cursor: default;
      .label {
        line-height: 1em;
      }
      .delete-icon {
        cursor: pointer;
        margin-right: 4px;
        &:hover {
          opacity: 0.5;
        }
      }
    }
    .placeholder {
      color: #a19a9a;
    }
  }

  .react-datepicker__day--highlighted, .react-datepicker__month-text--highlighted, .react-datepicker__quarter-text--highlighted {
    background-color: #3ea3fc !important;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: transparent;
  }

  .react-datepicker__day--outside-month {
    color: #c9c9c9;
  }

  .date-picker__svg {
    width: 14px;
    height: 14px;
    margin: auto 10px;
  }

  .react-datepicker__header {
    background-color: $color-blue;
    border-color: $color-blue;
    border-radius: 0;
  }

  .react-datepicker__current-month, .react-datepicker-time__header,
  .react-datepicker__day-name, .react-datepicker__time-name {
    color: #ffffff;
  }

  .react-datepicker__navigation--next {
    border-left-color: #ffffff;

    &:hover {
      border-left-color: #ffffff;
    }
  }

  .react-datepicker__navigation--previous {
    border-right-color: #ffffff;

    &:hover {
      border-right-color: #ffffff;
    }
  }

  .react-datepicker__day--keyboard-selected {
    background-color: $color-blue;
    transition: all 0.3s;

    &:hover {
      background-color: $color-blue-hover;
    }
  }

  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    border-bottom-color: $color-blue;
    margin-top: -7px;
  }

  .react-datepicker {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    border: none;
  }

  .react-datepicker__time-container {
  }

  .react-datepicker__day--selected, .react-datepicker__day--in-range, .react-datepicker__time-list-item--selected {
    background-color: $color-blue-hover !important;
  }
}
