.autocomplete-placeholder {
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #000000;
}

.autocomplete-placeholder-container {
    display: flex;
    flex-direction: row-reverse;
    align-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #000000;
}

.autocomplete-placeholder-image {
    background-image: url('./../assets/container/areas/search-icon.png');
    background-size: 100% 100%;
    width: 15px;
    height: 15px;
    margin-left: 7px;
}

.autocomplete-option,
.multiselect-option {

    width: 100%;
    height: 33px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;

}

.autocomplete-option:hover {
    background-color: #6d7278 !important;
}

.multiselect-option:hover {
    background-color: #6d7278 !important;
}

.autocomplete-checkbox-checked,
.multiselect-checkbox-checked {
    width: 26px;
    height: 26px;
    background-image: url('./../assets/container/areas/checkmark-on-white.png');
    background-size: 100% 100%;
}

.autocomplete-checkbox-unchecked,
.multiselect-checkbox-unchecked {
    margin: 0px 4px;
    width: 18px;
    height: 18px;
    opacity: 0.8;
    border-radius: 100px;
    background-color: #2c2c2e;
}

.multiselect-placeholder {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    align-content: center;
}

.multiselect-placeholder-text {
    font-size: 15px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: black;
    padding-right: 8px;
}

.multiselect-placeholder-icon-checked {
    background-image: url('./../assets/container/areas/checked.png');
}

.multiselect-placeholder-icon-unchecked {
    background-image: url('./../assets/container/areas/unchecked.png');
}

.multiselect-placeholder-icon-checked,
.multiselect-placeholder-icon-unchecked,
.multiselect-placeholder-none-text {
    width: 35px;
    height: 35px;
    background-size: 100% 100%;
}

.multiselect-placeholder-none-text {
    background-image: url('./../assets/container/areas/unchecked-darkgrey.png');
}

.multiselect-placeholder-text-blue {
    font-weight: 500;
    color: #00b3de;
}

.multiselect-placeholder-text-white {
    color: black;
    font-weight: 600;
}

.availability-option {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.availability-option:hover {
    cursor: pointer;
    background-color: #E4E4E4 !important;
}

.multiselect-option-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}