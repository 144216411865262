.edit-areas,
.apply-to-all-service-areas {
    font-size: 11px;
    padding: 0 7px;

    white-space: nowrap;
    overflow: hidden;
}

.multiselect-option-edit {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.multiselect-checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.multiselect-checkbox-container,
.edit-areas {
    cursor: pointer;
}

.edit-subject-areas {
    width: 50%;
    height: 100%;
}

// modal 
.modal-content {
    .layout-container {
        width: 100%;

        .search-boxes-container {
            width: 100%;
        }

        .google-maps-container {
            width: 100%;
        }

        .google-maps-bottom-text-container {
            right: calc(100% - 148px);
        }

        .layout-form-line {
            display: none;
        }

        .layout-form-container {
            flex-direction: column;
        }
    }
}