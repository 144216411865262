.provider-details{
  display: flex;
  flex-direction: row;
  padding: 10px;
  border-radius: 10px;
  background-color: #326c91;
  margin-top: 30px;
  //align-self: center;

}

.provider-details-input{
  padding: 0 15px;
  color: white;
  font-size: 18px;
  font-weight: normal;

}