.layout-container {

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 0px 90px 90px 90px;

    /* Portrait and Landscape */
    @media only screen and (max-width: 736px) {
        margin-top: 43px;
    }

    // override material style
    .MuiFormLabel-root.Mui-focused,
    .MuiFormLabel-filled {
        right: 0;
        top: initial;
        bottom: 45px !important;
        left: initial;
        font-size: 15px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        transform: none;
        transition: none;
        transform-origin: unset;
        direction: ltr;

        /* Portrait and Landscape */
        @media only screen and (max-width: 736px) {
            font-size: 12px;
            bottom: 50px !important;
        }
    }

    .MuiInputLabel-animated {
        transition: none !important;
    }

    .MuiInput-underline:after {
        border-color: #c8c8c8 !important;
    }
}

.layout-form-container {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    min-height: 49vh;
    font-size: 16px;
    justify-content: center;
    width: 100%;

    .MuiFormControl-marginNormal {
        margin: 0 !important;
        padding: 0 !important;
        height: 61.5px !important;
    }

    /* Portrait and Landscape */
    @media only screen and (max-width: 736px) {
        width: 85% !important;
        flex-direction: column;
        margin-top: 0px;
    }
}

.layout-form-2 {
    box-sizing: initial;
}

.layout-form-1,
.layout-form-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;

    margin: 0 50px;

    /* Portrait and Landscape */
    @media only screen and (max-width: 736px) {
        width: 100%;
        align-items: center;
        align-content: center;
        margin-left: 0px;
        margin-right: 0px;
        border-right: none;
        padding-right: 0px;
    }
}

.layout-submit-button {
    border: none;
    width: 211px;
    height: 56px;
    border-radius: 30px;
    background-color: #00b3de;
    font-size: 19px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #f2f2f2;
    margin: 20px 0;
    cursor: pointer;
    align-self: center;

    /* Portrait and Landscape */
    @media only screen and (max-width: 736px) {
        width: 120px;
        height: 34.2px;
        border-radius: 30px;
        background-color: #00b3de;
    }
}

.layout-form-line {
    height: 400px;
    width: 1px;
    background-color: #c8c8c8;
}

.areas-or-reagion-switch {
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media only screen and (max-width: 736px) {
        justify-content: center;
    }
    display: none;
}

.areas-switch-text-1,
.areas-switch-text-2 {
    font-size: 15px;
    font-weight: 100;
}

.layout-labels {

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    .label-1,
    .label-2 {
        padding-bottom: 10px;
    }

    .label-1 {
        font-size: 21px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.21;
        letter-spacing: 0.37px;
        text-align: center;

        margin-top: 30px;

        /* Portrait and Landscape */
        @media only screen and (max-width: 736px) {
            font-size: 24px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;

        }
    }

    .label-2 {
        font-size: 15px;
        font-weight: 100;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;

        /* Portrait and Landscape */
        @media only screen and (max-width: 736px) {
            margin-bottom: 20px;
            font-size: 15px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;

            opacity: 0.7;
        }
    }
}
