@import "../settings/variable";

.button {
  min-width: 100px;
  display: inline-block;
  padding: 10px 22px;
  cursor: pointer;
  text-decoration: none;
  border-radius: 24px;
  //border: solid 1px $peacock-blue;
  background-color: $color-blue;
  height: 48px;
  line-height: 0.5em;
  transition: all 0.3s ease-in-out;
  &:not([disabled]):hover {
    transform: scale(1.05);
    font-size: 24px;
  }
}

button {
  border: none;
}

button:focus {
  outline: 0;
}

button:active {
  outline: 0;
}
