@import "../settings/variable";

.resource-management {
  padding-top: 43px;
  .page-title {
    padding-left: 30px;
    font-size: 32px;
    font-weight: bold;
    color: $color-main-blue;
  }
  .page-desc {
    padding-left: 30px;
    font-size: 23px;
    color: $color-main-black;
  }
  .smart-table-wrapper {
    // column filter
    .column-selector, .search-filters, .add-container {
      .title {
        min-height: 1em;
        font-size: 18px;
        color: $color-main-black;
        margin-bottom: 6px
      }
      .css-1rhbuit-multiValue {
        background-color: transparent;
        > div {
          color: $color-main-gray;
        }
      }
      .input-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      .search-filters-input {
        width: 100%;
        height: 36px;
        padding: 0 10px;
        margin: 0 10px;
        border-radius: 4px;
        border: solid 1px $color-main-light-gray;
        background-color: $color-main-white;
      }
      .search-filters-img {
        width: 16px;
      }
    }
    .add-container {
      display: flex;
      align-items: flex-start;
      justify-content: space-evenly;
      .button-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: fit-content;
        height: 36px;
        padding: 10px 20px;
        border-radius: 17px;
        background-color: $color-btn-blue;
        color: $color-main-white;
        .label {
          margin: 0 10px;
          color: $color-main-white;
        }
        transition: all 0.2s ease-in-out;
        &:hover {
          cursor: pointer;
          background-color: $color-btn-blue-hover;
        }
      }
    }
  }

  &.embeded {
    padding-top: 0;
    .card-body {
      background-color: transparent !important;
      padding: 0px;
    }
    .ReactTable {
      margin: inherit !important;
      .rt-thead {
        display: none !important;
      }
    }
  }

}
