.wrapper {
  &.rtl {
    .ReactTable {
      .rt-resizer {
        left: 0;
        right: initial;
        z-index: 0;
      }
    }
  }
}