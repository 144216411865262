.unavailability-form-container {
    padding: 0px 45px;
    margin-top: 10px;
    display:flex;
    align-items: center;

    .layout-submit-button {
        //margin-top: 140px;
        align-self: flex-start;
        margin: 20px 270px;
        height: 40px;
        width: 150px;
        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }
}

.unavailability-datetime-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 690px;
}

.unavailability-datetime {
    display: flex;
    flex-direction: row;
    margin: 10px;
}

.unavailability-plus-ltr {
     position: absolute;
     right: -40px;
     top: 14px;
     cursor: pointer;
 }

.unavailability-plus-rtl {
    position: absolute;
    left: -100px;
    top: 17px;
    cursor: pointer;
}

.unavailability-error {
    position: absolute;
    right: -270px;
    color: red;
    font-size: 18px;
    &.overlap-error {
        position: initial;
    }
}

.unavailability-container {
    padding: 0 0px;
    font-size:20px;
    width:100%;
    //display: flex;
    align-items: flex-start;
    .unavailability-title {
        margin-top: 32px;
        font-size: 26px;
        font-weight: normal;
        font-style: normal;
        letter-spacing: 0;
        text-align: center;
        color: black;
    }
    .unavailable-note {
        user-select: none;
        font-size: 22px;
        cursor: pointer;
        color: #00b3de;
    }
    >.unavailability-title {
        margin-top: 76px;
    }

    .unavailability-description {
        margin-top: 6px;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        letter-spacing: 0;
        text-align: center;
        color: black;

    }

    .button-row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        flex-direction: row;

        &:first-of-type {
            margin-top: 30px;
        }
    }

    .unavailability-separator {
        height: 1px;
        width: 100%;
        background-color: #c8c8c8;
        margin-top: 30px;
    }

    .not-available-items {
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        align-items: center;
        .not-available-item {
            display: flex;
            justify-content: space-around;
            align-items: center;
            max-width: 420px;
            width: 100%;

            .not-available-item-from, .not-available-item-to {
                display: flex;
                align-items: center;
                > span {
                    margin-right: 10px;
                    font-size: 14px;
                }
                > div {
                    cursor: default;
                    background-color: black;
                    color: white;
                    padding: 5px 10px;
                    box-sizing: border-box;
                    border-radius: 20px;
                    font-size: 12px;
                }
            }

            .actions {
                .edit-button, .delete-button {
                    padding: 6px;
                    border: 0;
                    outline: none;
                }
            }
            //.not-available-item-to {
            //  display: flex;
            //  > span {
            //    margin-right: 20px;
            //  }
            //  > div {
            //    background-color: black;
            //    color: white;
            //  }
            //}
        }
    }
}

.unavailability-modal {
    display: flex;
    align-self: center;
    width: 40%;
    margin-bottom: 150px;
    direction: ltr;
    .unavailability-modal-container {
        padding-bottom: 40px;
        .um-title {
            margin-top: 30px;
            font-size: 22px;
            font-weight: normal;
            font-style: normal;
            letter-spacing: 0;
            text-align: center;

        }
        .unavailability-add-edit-items {
            display: flex;
            flex-direction: column;

            margin-top: 30px;
            .unavailability-add-edit-item {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .uiae-from, .uiae-to {
                    display: flex;
                    align-items: center;

                    > span {
                        margin-right: 10px;
                        font-size: 14px;
                        //width: 32px;
                    }

                    .um-date-time-picker {
                        input {
                            padding: 5px 10px 5px 24px;
                            width: 130px;
                            font-size: 12px;
                        }
                    }

                    &.uiae-from {
                        margin-right: 10px;
                    }

                }
                @media only screen and (max-width: 350px) {
                    flex-direction: column;
                    justify-content: space-around;
                    &.unavailability-add-edit-item {
                        .uiae-from {
                            margin: 0;
                        }
                        .uiae-to {
                            margin-top: 10px;
                            > span {
                                width: 32px;
                            }
                        }
                    }
                }
            }
        }
        .um-buttons {
            display: flex;
            justify-content: center;
            button {
                margin: 30px 10px 0 10px;
            }
        }
    }
}
