.select-options {
  margin: 0 4px;
  border-radius: 3px;
  cursor: pointer;
  background: transparent;

  &:focus {
    outline: none;
  }

  option {

  }
}