.task-types-modal {
  padding: 20px 45px 35px 45px;
  > h3 {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #346d92;
  }

  .task-types {
    margin-top: 26px;
    .task-type {
      display: flex;
      align-items: center;
      > span {
        font-size: 16px;
      }
    }
  }

  .tm-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
    button {
      color: white;
      font-size: 16px;
      border: 0;
      width: 92px;
      height: 31px;
      border-radius: 17px;
      background-color: #326c91;
      display: flex;
      align-items: center;
      justify-content: center;

      &[type="submit"] {
        background-color: #316C91;
        color: white;
      }

      &[type="button"] {
        background-color: #E6E6E6;
        color: #326c91;
      }
    }
  }

}
