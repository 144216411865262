body[dir="rtl"] {
  ::-webkit-scrollbar {
    width: 6px!important;
    height: 6px!important;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,.2);
  }
  ::-webkit-scrollbar-track {
    background: hsla(0,0%,100%,.1);
  }
  .btn-toolbar {
    & > * {
      margin-left: 15px;
      margin-right: 0;
      &:last-child {
        margin-left: 0;
      }
    }
  }
  .ReactTable {
    .rt-resizer {
      left: 0;
      right: initial;
      z-index: 0;
    }
  }
  .MuiInputLabel-formControl {
    left: initial;
    right: 0;
  }
  .MuiInputLabel-shrink {
    transform-origin: top right;
  }
  .MuiInputAdornment-positionStart {
    margin-right: 0;
    margin-left: 8px;
  }
  .MuiInputAdornment-positionEnd {
    margin-left: 0;
    margin-right: 8px;
  }
  .checkbox-btn__label {
    padding-left: 0px;
    padding-right: 5px;
  }
}
