@import "../settings/variable";

.input-group {
  flex: 1;
  position: relative;
  justify-content: flex-start;
  flex-direction: column;
  margin: 12px 10px;
  min-width: 200px;
  &.hidden {
    display: none;
  }
}

.together {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  .label-wrapper {
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    padding-left: 5px;

    img {
      width: 24px;
      margin-right: 12px;
    }

    label {
    }
  }

  .input-wrapper {
    position: relative;
    width: 100%;

    .input-icon-wrapper {
      position: absolute;
      display: flex;
      align-items: center;
      left: 15px;
      height: 34px
    }
  }

  .label-input {
    background-color: $color-main-light-gray;
    min-height: 30px;
    display: flex;
    align-items: center;
  }

  input.input-field {
    color: #0067a5;
    width: 100%;
    height: 30px;
    font-size: 16px;
    border: none;
    padding: 0 10px;
    box-sizing: border-box;
    background-color: transparent;

    &:disabled {
      //color: #a19a9a;
      opacity: 0.5;
      cursor: not-allowed;
    }

    &[type="tel"] {
      direction: ltr;
      text-align: end;
    }
  }

  input:focus {
    outline: none;
  }

  input::placeholder {
    color: #a19a9a;
    //color: #0067a5;
  }

}

.error-color {
  color: $color-red;
}

.input-error-message {
  text-align: start;
  //padding: 5px 0;
  font-size: 14px;
  color: $color-red;
}

