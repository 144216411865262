.top-container {
  padding-top: 20px;
  padding-left: 30px;
  align-items: center;
}
  .main-text {
    font-size: 20px;
    font-color: #4a4a4a;
    color:#4a4a4a;
    margin-bottom: 20px;
    margin-left: 15px;
  }

  .table-desc{
    padding-left: 30px;
    font-size: 20px;
    color: #4a4a4a;
  }

.card-body{
  padding-top: 0;
  .smart-table-wrapper {
    .ReactTable {
      margin: 10px 0px
    }
  }
}

.custom-card-body{
  padding-right: 0;
  padding-left: 0;
}

.selected-eye-icon {
  width:25px;
  height: 25px;
  margin: 0px 10px;
  margin-top: 3px;
  color:#326c91;
}

.selected-pencil-icon {
  width:25px;
  height: 25px;
  margin: 0px 10px;
}