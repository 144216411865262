@import "variable";

.working-hours {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #eeeeee;
  .day-wrapper {
    min-width: calc(50% - 8px);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 20px;
    margin: 4px;
    border: 1px solid #dddddd;
    border-radius: 4px;
    &:not(:last-of-type) {
      border-bottom: 1px solid $color-main-light-gray;
    }
    .day-title {
      font-size: 16px;
      line-height: 16px;
      font-weight: bold;
      text-decoration: underline;
      margin-bottom: 10px;
    }
    .hours-wrapper {
      align-self: stretch;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0.5em 0;
      > * {
        margin: 4px;
      }
      .react-datepicker-wrapper {
        max-width: 80px;
        input {
          text-align: center;
        }
      }
    }
    .range-button {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px;
      font-weight: bold;
      color: $color-main-white;
      cursor: pointer;
      &:hover {
        opacity: 0.5;
      }
      &.add {
        margin-top: 0.5em;
        background-color: $color-main-blue;
      }
      &.delete {
        background-color: $color-btn-red;
        margin-left: 20px;
      }
    }
  }
}
